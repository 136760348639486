import * as selfserveActionTypes from '../actionTypes/selfserveActionTypes';
import { appointmentMaxDateSlot, parseSlotAvailabilityData, generateDeviceKey } from '../../utils/selfserve'
import { HOMECARE_PLANS } from '../../constants'
import { SR_RESET_FIELDS } from '../../constants/selfserve'
import Moment from 'moment';

interface State {
    mobileNumber: string,
    whatsAppOptIn: boolean | null,
    current_step: number,
    previousStepList: Array<number>,
    tracker_step: number,
    issuePageFrom:number,
    landed_on_page: string,
    addressPageFrom:number,
    token_id: string,
    planCovers?: object,
    planDetails: any,
    parentPlanDetails?:any,
    categoryDetails:any,
    categoryIssues:object,
    deviceDetails:any,
    serviceRequests?: any,
    cityDetails?: {
        city?: string,
        state?: string,
        disclaimers?: Array<string>,
    }
    appointmentProcessType: {
        recommendedProcessType?: string,
        processType?: string,
        appointmentDate1?: any,
        appointmentDate2?: any,
        appointmentTimeSlot1?: string,
        appointmentTimeSlot2?: string,
    }
    srCapacityData?: object,
    srCreationSource?: string,
    sucessData:object,
    failureData:object,
    urlLanded:boolean,
    slotSelectionData: any,
    slotAvailabilityData?: object,
    multiple_enabled: boolean,
    ticket_list: object,
    current_ticket_key: string,
    customerDetails:object,
    editScreen:{
        isEditing:boolean,
        editingFrom: string | number | null,
        prevCoverCount:object,
    },
    coverCount:object,
    restoreTicketKey:string,
    deviceKey:String,
    edit: boolean,
    copaymentDetails: object,
    isServicePGChargesApplicable: boolean
}

const initialState: State = {
    mobileNumber: '',
    whatsAppOptIn: true,
    current_step: 1,
    previousStepList:[],
    tracker_step:1,
    issuePageFrom: 3,
    landed_on_page: '',
    addressPageFrom: 3,
    token_id: '',
    planCovers: [],
    planDetails: {},
    parentPlanDetails:{},
    categoryDetails: {},
    categoryIssues: {},
    deviceDetails: {},
    serviceRequests: {
        0:{}
    },
    cityDetails: {
        city: '',
        state: '',
        disclaimers: []
    },
    appointmentProcessType: {
        recommendedProcessType: '',
        processType: '',
        appointmentDate1: null,
        appointmentDate2: null,
        appointmentTimeSlot1: (new Date().getHours() < 14) ? "10AM - 2PM" : "2PM - 6PM",
        appointmentTimeSlot2: (new Date().getHours() < 14) ? "10AM - 2PM" : "2PM - 6PM",
    },
    srCapacityData: {},
    sucessData: {},
    failureData:{},
    srCreationSource: 'selfserve website',
    urlLanded: false,
    slotAvailabilityData: {
        maxDateOne: Moment(appointmentMaxDateSlot(6), 'DD/MM/YYYY'),
        maxDateTwo: Moment(appointmentMaxDateSlot(7), 'DD/MM/YYYY'),
        disabledDates: [],
        dateSlotMapping: null,
        firstAvailableDate: null,
        secondAvailableDate: null,
        tPlus3Date: Moment(appointmentMaxDateSlot(3), 'DD/MM/YYYY').toDate(),
    },
    slotSelectionData: [],
    multiple_enabled: false,
    ticket_list: {},
    current_ticket_key:'',
    customerDetails: {
        address:{
            address:null,
            pincode:null,
            city:null,
            state:null,
            address2:null,
            landmark: null,
            label: null,
            id: null,
            lat:null,
            lng:null
        }
    },
    editScreen: {
        isEditing:false,
        editingFrom:null,
        prevCoverCount:{},
    },
    coverCount:{},
    restoreTicketKey: '',
    deviceKey:'',
    edit:false,
    copaymentDetails: {},
    isServicePGChargesApplicable: false
}

const restoreTicket = (state, action) => {
  let restoreDict = {};
  let restoreSource = state.ticket_list[action.key];
  SR_RESET_FIELDS.forEach((field) => {
    try {
      restoreDict[field] = restoreSource[field];
    } catch (err) {
      restoreDict[field] =  initialState[field] ? initialState[field] : undefined;
    }
  });
  return {
    ...state,
    ...restoreDict,
    current_ticket_key:action.key,
  };
};

export default function selfserveReducer(state = initialState, action: any) {
    switch (action.type) {
        case selfserveActionTypes.UPDATE_MOBILE_NUMBER:
            return {
                ...state,
                mobileNumber: action.data
            }

        case selfserveActionTypes.UPDATE_WHATSAPP_CHECK:
            return {
                ...state,
                whatsAppOptIn: action.data
            }

        case selfserveActionTypes.UPDATE_CURRENT_STEP:
            let previousStep = state.current_step
            let stepList = state.previousStepList
            if (!state.editScreen.isEditing){
             stepList.push(previousStep)
            }else{
                stepList.pop()
            }
            action.data = (state.editScreen.isEditing && !action.force)
                ? state.editScreen.editingFrom
                : action.data
            return {
                ...state,
                current_step: action.data,
                previousStepList:stepList,
                editScreen: {
                    isEditing:false,
                    editingFrom:null,
                    prevCoverCount:{}
                },
                restoreTicketKey:'',
            }

        case selfserveActionTypes.UPDATE_PREVIOUS_STEP:
            let previousStepList = state.previousStepList
            let updatedCurrent_step = previousStepList.pop()
            let prevCoverCount = state.editScreen.prevCoverCount;
            let currentCoverCount: any = state.coverCount
            if (state.editScreen.isEditing && state.current_step == 4) {
                Object.keys(prevCoverCount).forEach(key =>{
                    currentCoverCount[key].push(prevCoverCount[key])
                })
            }
            if(action.key){
                if( state.ticket_list[action.key]){
                    let restoreState = restoreTicket(state,action)
                    return {
                        ...restoreState,
                        current_step: updatedCurrent_step,
                        previousStepList: previousStepList,
                        editScreen: {
                            isEditing:false,
                            editingFrom:null,
                            prevCoverCount:{}
                        },
                        coverCount:currentCoverCount,
                        restoreTicketKey:'',
                    }
                }else{
                    return {
                        ...state,
                        current_step: 14,
                        previousStepList:[3],
                        editScreen: {
                            isEditing:false,
                            editingFrom:null,
                            prevCoverCount:{}
                        },
                        current_ticket_key:'',
                        coverCount:currentCoverCount,
                        restoreTicketKey:'',
                    }
                }
            }
            return {
                ...state,
                current_step: updatedCurrent_step,
                previousStepList: previousStepList,
                editScreen: {
                    isEditing:false,
                    editingFrom:null,
                    prevCoverCount:{}
                },
                coverCount:currentCoverCount,
                restoreTicketKey:'',

            }

        case selfserveActionTypes.UPDATE_ISSUE_PAGE_FROM:
            return {
                ...state,
                issuePageFrom: action.data
            }

        case selfserveActionTypes.UPDATE_LANDED_ON_PAGE:
            return {
                ...state,
                landed_on_page: action.data
            }

        case selfserveActionTypes.UPDATE_ADDRESS_PAGE_FROM:
            return {
                ...state,
                addressPageFrom: action.data
            }

        case selfserveActionTypes.UPDATE_TRACKER_STEP:
            return {
                ...state,
                tracker_step: action.data
            }

        case selfserveActionTypes.UPDATE_TOKEN_ID:
            return {
                ...state,
                token_id: action.data
            }
        case selfserveActionTypes.UDPATE_USER_PLANS:
            return {
                ...state,
                userPlans: action.data,
            }

        case selfserveActionTypes.UPDATE_SELECTED_PLAN:
            let temp_list = JSON.parse(JSON.stringify(state.serviceRequests))
            if (!HOMECARE_PLANS.includes(action.data.plan_title))
                temp_list = [{ device_detail: action.data.device_detail }]
            return {
                ...state,
                planDetails: action.data,
                serviceRequests: {
                    ...state.serviceRequests,
                    ...temp_list
                },
                deviceDetails: {}
            }
        case selfserveActionTypes.UPDATE_ISSUES_FETCHED:
            return {
                ...state,
                categoryIssues: action.data,
            }

        case selfserveActionTypes.PLAN_COVERS_FETCHED:
            return {
                ...state,
                planCovers: action.data,
            }

        case selfserveActionTypes.UPDATE_SELECTED_COVER:
            temp_list = JSON.parse(JSON.stringify(state.serviceRequests))
            let srPayload = temp_list[action.data.index]
            if (!srPayload.hasOwnProperty('selected_cover'))
                srPayload.selected_cover = {}
            srPayload.selected_cover['cover_details'] = action.data.selectedCover
            if (srPayload.hasOwnProperty('selected_problems') && !action.data.selectedCover?.map(cover => { return cover.type }).includes('non_service'))
                srPayload.selected_problems = []
                srPayload.selected_problems_details = []
            return {
                ...state,
                serviceRequests: {
                    ...state.serviceRequests,
                    ...temp_list
                }
            }

        case selfserveActionTypes.REMOVE_SELECTED_COVER:
            temp_list = JSON.parse(JSON.stringify(state.serviceRequests))
            srPayload = temp_list[action.data.index]
            if (srPayload.hasOwnProperty('selected_cover'))
                delete srPayload.selected_cover
            return {
                ...state,
                serviceRequests: {
                    ...state.serviceRequests,
                    ...temp_list
                }
            }



        case selfserveActionTypes.UPDATE_CUSTOM_ISSUE:
            temp_list = JSON.parse(JSON.stringify(state.serviceRequests))
            srPayload = temp_list[action.data.index]
            srPayload.custom_issue = action.data.custom_issue
            return {
                ...state,
                serviceRequests: {
                    ...state.serviceRequests,
                    ...temp_list
                }
            }

        case selfserveActionTypes.UPDATE_SELECTED_PROBLEMS:
            temp_list = JSON.parse(JSON.stringify(state.serviceRequests))
            srPayload = temp_list[action.data.index]
            srPayload.selected_problems = action.data.selectedProblems
            srPayload.selected_problems_details = action.data.selectedProblemsDetails

            return {
                ...state,
                serviceRequests: {
                    ...state.serviceRequests,
                    ...temp_list
                }
            }

        case selfserveActionTypes.UPDATE_SR_REOPEN_REASON:
            temp_list = JSON.parse(JSON.stringify(state.serviceRequests))
            srPayload = temp_list[action.data.index]
            srPayload.sr_reopen_reason = action.data.reason
            srPayload.comment = action.data.comment
            return {
                ...state,
                serviceRequests: {
                    ...state.serviceRequests,
                    ...temp_list
                }
            }

        case selfserveActionTypes.UPDATE_ADDRESS_DETAILS:
            temp_list = JSON.parse(JSON.stringify(state.customerDetails))
            temp_list['address'] = action.data.address

            return {
                ...state,
                customerDetails: {
                    ...state.customerDetails,
                    ...temp_list
                }
            }

        case selfserveActionTypes.UPDATE_CITY_DETAILS:
            return {
                ...state,
                cityDetails: action.data
            }

        case selfserveActionTypes.UPDATE_PROCESS_TYPE:
            temp_list = JSON.parse(JSON.stringify(state.appointmentProcessType))
            temp_list['processType'] = action.data.process_type
            temp_list['recommendedProcessType'] = action.data.process_type
            // temp_list['qc_rvp'] = action.data.qc_rvp
            return {
                ...state,
                appointmentProcessType: {
                    ...state.appointmentProcessType,
                    ...temp_list
                }
            }

        case selfserveActionTypes.UPDATE_APPOINTMENT_DATE:
            temp_list = JSON.parse(JSON.stringify(state.appointmentProcessType))
            temp_list['appointmentDate1'] = action.data.appointment_date_1
            temp_list['appointmentDate2'] = action.data.appointment_date_2

            temp_list['appointmentTimeSlot1'] = action.data.appointment_slot_1
            temp_list['appointmentTimeSlot2'] = action.data.appointment_slot_2

            return {
                ...state,
                appointmentProcessType: {
                    ...state.appointmentProcessType,
                    ...temp_list
                }
            }

        case selfserveActionTypes.UPDATE_SR_CAPACITY_DATA:
            return {
                ...state,
                srCapacityData: action.data
            }

        case selfserveActionTypes.UPDATE_SLOT_AVAILABILITY_DATA:
            temp_list = JSON.parse(JSON.stringify(state.slotAvailabilityData))
            let {disabledDates, dateSlotMapping, less_than_minimum_slots} = parseSlotAvailabilityData(action.data)
            temp_list['disabledDates'] = disabledDates
            temp_list['dateSlotMapping'] = dateSlotMapping
            // temp_list['maxDateOne'] = Moment(appointmentMaxDateSlot(6), 'DD/MM/YYYY')
            // temp_list['maxDateTwo'] = Moment(appointmentMaxDateSlot(7), 'DD/MM/YYYY')
            temp_list['firstAvailableDate'] = Moment(Object.keys(dateSlotMapping).sort()[0], 'YYYY-MM-DD').toDate()
            temp_list['secondAvailableDate'] = Moment(Object.keys(dateSlotMapping).sort()[1], 'YYYY-MM-DD').toDate()
            temp_list['slotsAvailable'] = true
            temp_list['error'] = ''
            temp_list['less_than_minimum_slots'] = less_than_minimum_slots;
            return {
                ...state,
                slotAvailabilityData: {
                    ...state.slotAvailabilityData,
                    ...temp_list
                }
            }

        case selfserveActionTypes.REMOVE_SLOT_AVAILABILITY_DATA:
            temp_list = JSON.parse(JSON.stringify(state.slotAvailabilityData))
            if(action.data){
                temp_list['slotsAvailable'] = false
                temp_list['error'] = action.data.error
            }
            temp_list['disabledDates'] = []
            temp_list['dateSlotMapping'] = null
            // temp_list['maxDateOne'] = undefined
            // temp_list['maxDateTwo'] = undefined
            temp_list['firstAvailableDate'] = null
            temp_list['secondAvailableDate'] = null
            return {
                ...state,
                slotAvailabilityData: {
                    ...state.slotAvailabilityData,
                    ...temp_list
                }
            }



        case selfserveActionTypes.UPDATE_TEMP_SR_ID:
            temp_list = JSON.parse(JSON.stringify(state.serviceRequests))
            srPayload = temp_list[action.data.index]
            srPayload.temp_sr_id = action.data.onsite_sr_id
            return {
                ...state,
                serviceRequests: {
                    ...state.serviceRequests,
                    ...temp_list
                }
            }

        case selfserveActionTypes.UPDATE_SUCCESS_DATA:
            return {
                ...state,
                sucessData: action.data
            }

        case selfserveActionTypes.UPDATE_FAILURE_DATA:
            return {
                ...state,
                failureData: action.data
            }

        case selfserveActionTypes.UPDATE_SR_CREATION_SOURCE:
            return {
                ...state,
                srCreationSource: action.data
            }

        case selfserveActionTypes.URL_LANDED:
            return {
                ...state,
                urlLanded: action.data
            }

        case selfserveActionTypes.ADD_SR_SLOT_SELECTION_DATA:
            return {
                ...state,
                slotSelectionData: [action.data],
            }

        case selfserveActionTypes.PUSH_TO_TICKET_LIST:
            let currentPlanDetails:any = {};
            let key:string = `${state.planDetails.osid}`
            let deviceKey:string | null = null;
            if (!state.deviceDetails.deviceKey) {
              let uniqueKey:string = generateDeviceKey(state.planDetails, state.deviceDetails);
              key = `${key}-${uniqueKey}`;
              deviceKey = uniqueKey;
            } else {
              key = `${key}-${state.deviceDetails.deviceKey}`
              deviceKey = state.deviceDetails.deviceKey
            }
            SR_RESET_FIELDS.forEach(field => {
              try {
                currentPlanDetails[field] = state[field]
              } catch(err) {
                currentPlanDetails[field] = undefined
              }
            });
            currentPlanDetails.deviceDetails.deviceKey = deviceKey;
            let coverCount = state.coverCount
            if (HOMECARE_PLANS.includes(state.parentPlanDetails?.plan_title)){
                const coverId = state.serviceRequests[0]?.selected_cover?.cover_details[0]?.cover_id
                if(coverId){
                    Object.keys(coverCount).map((coverId)=>{
                        if(coverCount.hasOwnProperty(coverId) && coverCount[coverId].includes(key)){
                            coverCount[coverId] = coverCount[coverId].filter(coverKey=> coverKey!=key)
                        }
                    })
                    if (!coverCount.hasOwnProperty(coverId)) {
                      coverCount[coverId] = [key];
                    } else {
                      coverCount[coverId].push(key);
                    }
                }
            }
            return {
              ...state,
              ticket_list: {
                ...state.ticket_list,
                [key]: currentPlanDetails
              },
              current_ticket_key:key,
              deviceDetails: {
                ...state.deviceDetails,
                deviceKey: deviceKey
              },
              coverCount:coverCount,
            }

        case selfserveActionTypes.RESTORE_FROM_TICKET_LIST:
            return restoreTicket(state,action)

        case selfserveActionTypes.RESET_TICKET_DATA:
            let resetData = {}
            SR_RESET_FIELDS.forEach(field => {
              try {
                resetData[field] = initialState[field];
              } catch (error) {
                console.log(error);
              }
            });
            return {
                ...state,
                ...resetData,
            }

        case selfserveActionTypes.UPDATE_MULTIPLE_ENABLED:
            return {
                ...state,
                multiple_enabled: action.data
            }

        case selfserveActionTypes.UPDATE_CATEGORY_DETAILS:
            return {
              ...state,
              categoryDetails: {
                ...state.categoryDetails,
                ...action.data
                },
            };

        case selfserveActionTypes.UPDATE_DEVICE_DETAILS:
          if (!state.deviceDetails.deviceKey || action.forceKeygen) {
            action.data['deviceKey'] = generateDeviceKey(state.planDetails, action.data);
          } else {
            if (!action.data.deviceKey) {
              action.data['deviceKey'] = state.deviceDetails.deviceKey;
            }
          }
          let serviceRequests = state.serviceRequests
          if(action.cleanServiceRequests){
            serviceRequests = { 0:{} }
          }
          return {
              ...state,
              deviceDetails: {...action.data, id: state.planDetails?.device_detail?.device_id || null},
              serviceRequests: serviceRequests,
          };

        case selfserveActionTypes.UPDATE_PARENT_DEVICE_DETAILS:
            return {
                ...state,
                parentPlanDetails: {
                ...action.data
                },
            };

        case selfserveActionTypes.UPDATE_EDIT_SCREEN_DETAILS:
            let restoreTicketKey = state.deviceDetails.deviceKey
            let updateCoverCount = state.coverCount
            const selectCoverId = state.serviceRequests[0]?.selected_cover?.cover_details[0]?.cover_id
            if (selectCoverId) {
              updateCoverCount[selectCoverId] = updateCoverCount[selectCoverId]
                ? updateCoverCount[selectCoverId].filter(
                    (coverKey) => coverKey != action.key
                  )
                : 0;
            }
            return {
                ...state,
                editScreen: {
                isEditing:true,
                editingFrom:action.data,
                prevCoverCount:{[selectCoverId]:action.key}
                },
                restoreTicketKey:restoreTicketKey,
                coverCount:updateCoverCount,

            };

        case selfserveActionTypes.REMOVE_ADDED_DEVICES:
            let ticket_list = state.ticket_list
            let restoreDevice = state
            if (state.current_ticket_key === action.key) {
              let lastKey = Object.keys(ticket_list).filter(
                (key) => key === action.key
              )[0];
              restoreDevice = restoreTicket(state, { ...action, key: lastKey });
              restoreDevice["current_ticket_key"] = action.key
            }
            delete ticket_list[action.key]

            let preCoverCountObj = state.coverCount
            const coverId = state.serviceRequests[0]?.selected_cover?.cover_details[0]?.cover_id
            if(coverId && HOMECARE_PLANS.includes(state.parentPlanDetails.plan_name)){
                Object.keys(preCoverCountObj).map((coverId)=>{
                    if(preCoverCountObj.hasOwnProperty(coverId) && preCoverCountObj[coverId].includes(action.key)){
                        preCoverCountObj[coverId] = preCoverCountObj[coverId].filter(coverKey=> coverKey!=action.key)
                    }
                })
            }
            return {
                ...restoreDevice,
                ticket_list: {
                    ...state.ticket_list,
                  },
                coverCount:preCoverCountObj
            }

        case selfserveActionTypes.REMOVE_SELECTED_PROBLEMS:
          return {
            ...state,
            serviceRequests: {
              0: {
                ...state.serviceRequests[0],
                selected_problems: undefined,
                selected_problems_details: undefined,
                custom_issue: undefined,
              },

            },
          }
        case selfserveActionTypes.UPDATE_EDIT_MODE:
            return {
                ...state,
                edit: action.data
            }

        case selfserveActionTypes.UPDATE_COPAYMENT_DETAILS:
            return {
                ...state,
                copaymentDetails: action.data
            }
        case selfserveActionTypes.UPDATE_IS_SERVICE_PG_CHARGES_APPLICABLE:
                return {
                    ...state,
                    isServicePGChargesApplicable: action.data
                };

        default:
            return state;
    }
}
