export const UPDATE_MOBILE_NUMBER = "UPDATE_MOBILE_NUMBER"

export const UPDATE_WHATSAPP_CHECK = "UPDATE_WHATSAPP_CHECK"

export const UPDATE_CURRENT_STEP = "UPDATE_CURRENT_STEP"

export const UPDATE_TRACKER_STEP = "UPDATE_TRACKER_STEP"

export const UPDATE_ISSUE_PAGE_FROM = "UPDATE_ISSUE_PAGE_FROM"

export const UPDATE_LANDED_ON_PAGE = "UPDATE_LANDED_ON_PAGE"

export const UPDATE_ADDRESS_PAGE_FROM = "UPDATE_ADDRESS_PAGE_FROM"

export const UPDATE_CUSTOM_ISSUE = "UPDATE_CUSTOM_ISSUE"

export const UPDATE_TOKEN_ID = "UPDATE_TOKEN_ID"

export const UDPATE_USER_PLANS = "UPDATE_USER_PLANS"

export const UPDATE_SELECTED_PLAN = "UPDATE_SELECTED_PLAN"

export const UPDATE_ISSUES_FETCHED = "UPDATE_ISSUES_FETCHED"

export const PLAN_COVERS_FETCHED = "PLAN_COVERS_FETCHED"

export const UPDATE_SELECTED_COVER = "UPDATE_SELECTED_COVER"

export const UPDATE_SELECTED_PROBLEMS = "UPDATE_SELECTED_PROBLEMS"

export const UPDATE_SR_REOPEN_REASON = "UPDATE_SR_REOPEN_REASON"

export const UPDATE_ADDRESS_DETAILS = "UPDATE_ADDRESS_DETAILS"

export const UPDATE_CITY_DETAILS = "UPDATE_CITY_DETAILS"

export const UPDATE_PROCESS_TYPE = "UPDATE_PROCESS_TYPE"

export const UPDATE_APPOINTMENT_DATE = "UPDATE_APPOINTMENT_DATE"

export const UPDATE_SR_CAPACITY_DATA = "UPDATE_SR_CAPACITY_DATA"

export const UPDATE_TEMP_SR_ID = "UPDATE_TEMP_SR_ID"

export const UPDATE_SUCCESS_DATA = "UPDATE_SUCCESS_DATA"

export const UPDATE_FAILURE_DATA = "UPDATE_FAILURE_DATA"

export const UPDATE_SR_CREATION_SOURCE = "UPDATE_SR_CREATION_SOURCE"

export const URL_LANDED = "URL_LANDED"

export const REMOVE_SELECTED_COVER = "REMOVE_SELECTED_COVER"

export const UPDATE_SLOT_AVAILABILITY_DATA = "UPDATE_SLOT_AVAILABILITY_DATA"

export const REMOVE_SLOT_AVAILABILITY_DATA = "REMOVE_SLOT_AVAILABILITY_DATA"

export const ADD_SR_SLOT_SELECTION_DATA = "ADD_SR_SLOT_SELECTION_DATA"
export const PUSH_TO_TICKET_LIST = "PUSH_TO_TICKET_LIST"

export const RESTORE_FROM_TICKET_LIST = "RESTORE_FROM_TICKET_LIST"

export const RESET_TICKET_DATA = "RESET_TICKET_DATA"

export const UPDATE_MULTIPLE_ENABLED = "UPDATE_MULTIPLE_ENABLED"

export const UPDATE_CATEGORY_DETAILS = "UPDATE_CATEGORY_DETAILS"

export const UPDATE_DEVICE_DETAILS = "UPDATE_DEVICE_DETAILS"

export const UPDATE_PARENT_DEVICE_DETAILS = "UPDATE_PARENT_DEVICE_DETAILS"

export const UPDATE_PREVIOUS_STEP = "UPDATE_PREVIOUS_STEP"

export const UPDATE_EDIT_SCREEN_DETAILS = "UPDATE_EDIT_SCREEN_DETAILS"

export const REMOVE_ADDED_DEVICES = "REMOVE_ADDED_DEVICES"

export const REMOVE_SELECTED_PROBLEMS = "REMOVE_SELECTED_PROBLEMS"

export const UPDATE_EDIT_MODE = "UPDATE_EDIT_MODE"

export const UPDATE_COPAYMENT_DETAILS = "UPDATE_COPAYMENT_DETAILS"

export const UPDATE_IS_SERVICE_PG_CHARGES_APPLICABLE = "UPDATE_IS_SERVICE_PG_CHARGES_APPLICABLE"
