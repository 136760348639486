import 'url-search-params-polyfill';
import Config from '../config';
import Cookies from 'js-cookie'

export const dataToUrlParams = (data:any) => {
  let queryString = new URLSearchParams()
  for(let key in data){
    let value = data[key]
    queryString.append(key, value);
  }
  return queryString.toString();
}

export const GetCall = async (URL:string, DATA={}, HEADER={}) => {
  try{
    let csrf_token = Cookies.get('csrftoken')
    if(csrf_token){
      if(Config.DEBUG){
        HEADER['CSRF-TOKEN'] = csrf_token
      }
      HEADER['X-CSRFToken'] = csrf_token
    }
    let session_key = Cookies.get('session_id')
    if(session_key){
      HEADER['SESSION-KEY'] = session_key
    }
    let FINAL_URL = URL;
    if(typeof(DATA)=="object" && !!Object.keys(DATA).length){  
      FINAL_URL = URL + "?" + dataToUrlParams(DATA); 
    }
    const response = await fetch(FINAL_URL, {
      headers: HEADER 
    })
    if(response.ok){
      return {response: response, data: await response.json()};
    }
    else
      throw ({response:response})
  }catch(error){
    // throw(error);
  }
}

export const PostCall = async (URL:string, DATA={}, HEADER={}, responseRequired=true, kwargs={}) => {
  try{
    let csrf_token = Cookies.get('csrftoken')
    if(csrf_token){
      if(Config.DEBUG){
        HEADER['CSRF-TOKEN'] = csrf_token
      }
      HEADER['X-CSRFToken'] = csrf_token
    }
    let session_key = Cookies.get('session_id')
    if(session_key){
      HEADER['SESSION-KEY'] = session_key
    }
    let verified_phone = Cookies.get('verified_phone')
    if(verified_phone){
      HEADER['VERIFIED-PHONE'] = verified_phone
    }
    const response = await fetch(URL, {
      method: 'POST',
      headers: HEADER,
      body: JSON.stringify(DATA),
      ...kwargs
    })
    if(responseRequired){
      if(response.ok){
        return {response: response, data: await response.json()};
      }else{
        throw(response)
      }
    }
  } catch(err) {
    throw(err);
  }
}

export const PostCallMultiForm = async (URL:string, DATA, HEADER={}, responseRequired=true) => {
  try{
    let csrf_token = Cookies.get('csrftoken')
    if(csrf_token){
      if(Config.DEBUG){
        HEADER['CSRF-TOKEN'] = csrf_token
      }
      HEADER['X-CSRFToken'] = csrf_token
    }
    let session_key = Cookies.get('session_id')
    if(session_key){
      HEADER['SESSION-KEY'] = session_key
    }
    const response = await fetch(URL, {
      method: 'POST',
      headers: HEADER,
      body: DATA
    })
    if(responseRequired){
      if(response.ok){
        return {response: response, data: await response.json()};
      }else{
        throw (response)
      }
    }
  } catch(err) {
    throw(err);
  }
}

export const PatchCall = async (URL:string, DATA={}, HEADER={}, responseRequired=true) => {
  try{
    let csrf_token = Cookies.get('csrftoken')
    if(csrf_token){
      if(Config.DEBUG){
        HEADER['CSRF-TOKEN'] = csrf_token
      }
      HEADER['X-CSRFToken'] = csrf_token
    }
    let session_key = Cookies.get('session_id')
    if(session_key){
      HEADER['SESSION-KEY'] = session_key
    }

    // HEADER["Access-Control-Allow-Origin"] =  "*";
    // HEADER["Access-Control-Allow-Headers"] = "Accept"
    // HEADER["Access-Control-Allow-Methods"] = "GET,HEAD,OPTIONS,POST,PUT,PATCH";
    // HEADER["Access-Control-Allow-Headers"] =  "Origin, X-Requested-With, Content-Type, Accept, Authorization";

    const response = await fetch(URL, {
      method: 'PATCH',
      headers: HEADER,
      body: JSON.stringify(DATA)
    })
    if(responseRequired){
      if(response.ok){
        return {data: await response.json(), details: response};
      }else{
        throw(response.json())
      }
    }
  } catch(err) {
    
    throw(err);
  }
}


export const DeleteCall = async (URL:string, DATA={}, HEADER={}, responseRequired=true) => {
  try{
    let csrf_token = Cookies.get('csrftoken')
    if(csrf_token){
      if(Config.DEBUG){
        HEADER['CSRF-TOKEN'] = csrf_token
      }
      HEADER['X-CSRFToken'] = csrf_token
    }
    let session_key = Cookies.get('session_id')
    if(session_key){
      HEADER['SESSION-KEY'] = session_key
    }

    // HEADER["Access-Control-Allow-Origin"] =  "*";
    // HEADER["Access-Control-Allow-Headers"] = "Accept"
    // HEADER["Access-Control-Allow-Methods"] = "GET,HEAD,OPTIONS,POST,PUT,PATCH";
    // HEADER["Access-Control-Allow-Headers"] =  "Origin, X-Requested-With, Content-Type, Accept, Authorization";

    const response = await fetch(URL, {
      method: 'DELETE',
      headers: HEADER,
      body: JSON.stringify(DATA)
    })
    if(responseRequired){
      if(response.ok){
        return {data: await response.json(), details: response};
      }else{
        throw(response.json())
      }
    }
  } catch(err) {
    
    throw(err);
  }
}

export const GetCallWithError = async (URL:string, DATA={}, HEADER={}) => {
  try{
    let csrf_token = Cookies.get('csrftoken')
    if(csrf_token){
      if(Config.DEBUG){
        HEADER['CSRF-TOKEN'] = csrf_token
      }
      HEADER['X-CSRFToken'] = csrf_token
    }
    let session_key = Cookies.get('session_id')
    if(session_key){
      HEADER['SESSION-KEY'] = session_key
    }
    let FINAL_URL = URL;
    if(typeof(DATA)=="object" && !!Object.keys(DATA).length){  
      FINAL_URL = URL + "?" + dataToUrlParams(DATA); 
    }
    const response = await fetch(FINAL_URL, {
      headers: HEADER 
    })
    if(response.ok){
      return {response: response, data: await response.json()};
    }
    else
      throw ({response:response})
  }catch(error){
    throw(error);
  }
}

export const GetCallWithErrorDetails = async (URL:string, DATA={}, HEADER={}) => {
  try{
    let csrf_token = Cookies.get('csrftoken')
    if(csrf_token){
      if(Config.DEBUG){
        HEADER['CSRF-TOKEN'] = csrf_token
      }
      HEADER['X-CSRFToken'] = csrf_token
    }
    let session_key = Cookies.get('session_id')
    if(session_key){
      HEADER['SESSION-KEY'] = session_key
    }
    let FINAL_URL = URL;
    if(typeof(DATA)=="object" && !!Object.keys(DATA).length){  
      FINAL_URL = URL + "?" + dataToUrlParams(DATA); 
    }
    const response = await fetch(FINAL_URL, {
      headers: HEADER 
    })
    if(response.ok){
      return {response: response, data: await response.json()};
    }
    else
      throw ({response:response, data: await response.json()})
  }catch(error){
    throw(error);
  }
}

export const ThirdPartyGetCall = async (url:string) => {
  try{
    const response = await fetch(url)
    if(response.ok){
      return {response: response, data: await response.json()};
    }
    else
      throw ({response:response})
  }catch(error){
    console.log(error)
  }
}